﻿
html{
    font-size: 9px;
    line-height: initial;
    font-family: 'Poppins';
    max-width: 100vw;
    overflow-x: hidden;
    text-size-adjust: none !important;

    @include media-breakpoint-down(lg) {
        font-size: 9px;
    }

    @include media-breakpoint-down(md) {
        font-size: 8px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 7px;
    }
}
body {
    max-width: 100vw;
    overflow-x: hidden;
    margin: 0;
    padding: 0 !important;
    background: $color-white;
    text-size-adjust: none !important;
    font-family: 'Poppins';
}

#root-react{
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

a { 
    text-decoration: none !important;
    color: inherit !important;

    &:not([href]):not([tabindex]) {
        color: inherit !important;
    }

    &:hover, &:visited, &:active {
        text-decoration: inherit !important;
    }
}

a.anchor {
    display: block;
    position: relative;
    top: -11rem;
    text-align: center;
    visibility: visible;
    margin: auto;
}

p, h1, h2, h3, h4, h5, h6 {
    display: block;
    width: 100%;
    margin: 0;
    color: $color-white;
}

p {
    font-family: 'Poppins';
    font-size: 2rem;
    text-align: left;
    margin-bottom: 3rem;
    line-height: 1.3;
    color: $color-black;
    word-break: normal;
}

h1 {
    font-family: 'Arcon-Regular';
    text-transform: uppercase;    
    text-align: center;
    font-size: 5rem;
    color: $color-white;
    line-height: 1;
    margin: auto;
    white-space: pre-line;

    @include media-breakpoint-down(md) {
        white-space: normal;
    }    
}

h2 {
    font-family: 'Poppins';
    text-transform: uppercase;    
    text-align: center;
    font-size: 4rem;
    color: $color-white;
}

h3 {
    font-family: 'Poppins';
    text-transform: uppercase;    
    text-align: center;
    font-size: 3.5rem;
    color: $color-gold;
}

h4 {
    font-family: 'Poppins';
    text-transform: uppercase;    
    text-align: center;
    font-size: 3rem;
    color: $color-gold;
    white-space: pre-line;
}

h5{
    font-family: 'Poppins';
    text-transform: uppercase;   
    font-size: 1.2rem;
    text-align: center;
    color: #fff;
}


.cta{
    border-radius: 3px;
    transition: .5s;
    cursor: pointer;
    background-color: $color-yellow;
    white-space: nowrap;
    display: inline-block;

    p{
        margin: 1rem;
        font-family: 'Poppins';
        font-size: 1.4rem;  
        text-transform: uppercase;
        color: $color-gray;
        transition: .5s;
    }

    &:hover{
        background-color: $color-white !important;
    }
}



.modal-body{
    @include media-breakpoint-down(sm) {
        padding: 0 1.5rem !important;
    }
}


.termos{
    a{
        display: inline-block;
        margin: 0;
    }

    li{
        p{
            margin: 0;
        }
    }

    p{
        text-align: justify;
    }
}

.tooltip-inner{
    font-size: 1.2rem;
}

.step-disabled{
    pointer-events: none;
    opacity: .5;
    transform: scale(.75);
}


@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
  transform-origin: center;
}

.cookies{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: $gradient-gold no-repeat, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
    background-size: 100% .9rem, auto;
    padding-top: 3rem;

    p{
        color: $color-white;
    }
    .cta{
        margin: auto;

        p{
            color: $color-gray;
        }
    }
}

.icons{

    &__item{
        text-align: center;
        margin-top: 3rem;
        img{
            max-width: 15rem;
            margin: auto;
        }

        p{
            text-align: center;
            padding: 0 1.5rem;
        }

        h3{
            margin-top:  2.5rem;
            margin-bottom: 1rem;
        }
    }
}

.modal{

    &__img{
        width: 20rem;
        max-width: 60%;
        margin: -15rem auto 0;
    }

    &__text{
            text-align: center;        
    }

    &-header{
        .close{
            margin-left: 0;
        }
    }
}