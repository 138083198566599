.simulador{
    flex: 1;
    display: flex;
    align-items: center;
    padding: 15rem 0 5rem;
    background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg);

    &__bg--1{
        background: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/galo-simulador-bg1.jpg) no-repeat bottom right, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
        background-size: auto 90%, auto;
    }
    &__bg--2{
        background: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/galo-simulador-bg2.jpg) no-repeat bottom right, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
        background-size: auto 90%, auto;
    }
    &__bg--3{
        background: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/galo-simulador-bg3.jpg) no-repeat bottom right, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
        background-size: auto 90%, auto;
    }
    &__bg--4{
        background: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/galo-simulador-bg4.jpg) no-repeat bottom left, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
        background-size: auto 90%, auto;
    }
    &__bg--5{
        background: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/galo-simulador-bg5.jpg) no-repeat bottom left, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
        background-size: auto 90%, auto;
    }

    &__conteudo{
        &__tooltip-icon{
            svg{
                width: 2rem;
                height: 2rem;
            }
        } 

        p{
            color: $color-white;
        }

        h4{
            font-size: 2.5rem;
            color: $color-white;
        }

        h5{
            text-align: center;
        }

        &__share{
            margin: 2rem auto;
            display: inline-block;
            
            &__icon{
                background-color: $color-gray;
                border-radius: 50%;
                padding: 1.5rem;
                display: inline-flex;
                justify-content: space-around;
                align-items: center;
                color: $color-white !important;
                transition: .5s;
                margin: 0 2rem;
    
                svg{
                    width: 5rem;
                    height: 5rem;
                }
    
                &:hover{
                    background-color: $color-yellow !important;
                }
            }
        }

        &__selo{
            max-width: 15rem;
            margin-bottom: 3rem;
            @include media-breakpoint-down(sm) { 
                max-width: 13rem;
            }
        }
    }
}