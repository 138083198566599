﻿$color-black: #000000;
$color-white: #ffffff;
$color-gray: #575859;
$color-gray-light: #b8bec4;
$color-green: #5EC7D0;
$color-gold: #9e7f02;
$color-yellow: #FDD936;
$color-red: #B30C0C;
$color-whatsapp: #25D366;

$color-header-bg: #101011;

$color-gradient-gold-1: #AE782B;
$color-gradient-gold-2: #F7C82D;
$color-gradient-gold-3: #FAF7CA;
$color-gradient-gold-4: #FFCF2E;

$gradient-gold: linear-gradient(to right, $color-gradient-gold-1 0%, $color-gradient-gold-2 16.85%, $color-gradient-gold-3 33.15%, $color-gradient-gold-3 35.76%, $color-gradient-gold-4 77.58%, $color-gradient-gold-2 84.27%, $color-gradient-gold-1 100%);

$theme-colors: (
  'black':	$color-black,
  'white':	$color-white,
  'gray':	$color-gray,
  'gold':	$color-gold,
  'gray-light':	$color-gray-light,
  'red':	$color-red,
  'yellow':	$color-yellow,
  'green':	$color-green,
  "whatsapp": $color-whatsapp,
  "gradient-gold-1": $color-gradient-gold-1,
  "gradient-gold-2": $color-gradient-gold-2,
  "gradient-gold-3": $color-gradient-gold-3,
  "gradient-gold-4": $color-gradient-gold-4,
);


@mixin hover-focus {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin custom-bg-variant($parent, $value) {
    #{$parent} {
        background-color: $value !important;
    }

    a#{$parent},
    button#{$parent} {
    
        @include hover-focus {
            background-color: darken($value, 10%) !important;
        }
    }
}

@mixin custom-color-variant($parent, $value) {
    #{$parent} {
        color: $value  !important;

        h1,h2,h3,h4,p{
            color: $value !important;
        }
    }
}

@each $color, $value in $theme-colors {
    @include custom-color-variant('.color-#{$color}',$value);
    @include custom-bg-variant('.bg-#{$color}', $value);
}
