.navigation{
    position: fixed;
    width: 100vw;
    top: 0;
    padding: 1.5rem 0 1rem;
    transition: .5s;
    z-index: 9;
    background: $gradient-gold no-repeat, url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg) repeat;
    background-size: 100% .6rem, auto;
    max-height: 20rem;

    &--interno{
        background-image: none !important;
        background-color: $color-white !important;
        min-height: 3rem !important;

        .navigation{

            &__logo{
                img{
                    &:first-child{
                        height: 7.5rem;
                    }
        
                    &:last-child{
                        height: 3.75rem;
                        width: auto;
                        transition: .5s;
                    }
                }
            }

            &__bars{
                       
                &:hover{
                    svg{
                        color: $color-gray !important;
                    }
                }
            }


            &__content{
                margin-top: auto !important;
                background: $color-white;

                &__menu, &__menu--tel{
                    a p{
                        color: $color-gray !important;
                    }


                }
            }
        }

        .cta{ 
            display: none !important;
        }
    }

    &__bars{
        margin: auto 2rem auto 0;
        transition: .5s;
        padding-top: .5rem;
        cursor: pointer;
        display: none;
        
        svg{
            color: $color-yellow !important;
            transition: .5s;
            height: 3.5rem;
        }

        &:hover{
            svg{
                color: $color-white !important;
            }
        }
    }

    &__logo{
        margin: 1rem 2rem 1rem 0;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        transition: .5s;

        img{
            height: 9rem;
            width: auto;
            transition: .5s;
        }
    }

    &__content{
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        margin: 2rem auto auto;
        width: 100%;
        transition: .5s;

        &__menu{            
            a{
                display: inline-flex;
                margin-right: 2rem;
                cursor: pointer;

                &:first-child{
                    margin-left: 0;
                }

                p{
                    font-size: 1.4rem;  
                    text-transform: uppercase;
                    color: $color-white;
                    transition: .5s;
                    margin-bottom: 0;

                    &:hover{
                        color: $color-yellow !important;
                    }

                    svg{
                        width: 1.4rem;
                    }
                }
            }

            &--tel{
                width: 100%;
                padding-top: .5rem;
                a{
                    display: inline-flex;
                    margin: auto 4rem auto auto;
                    cursor: pointer;

                    p{
                        font-size: 2rem;  
                        text-transform: uppercase;
                        color: $color-white;
                        transition: .5s;
                        margin-bottom: 0;
    
                        &:hover{
                            color: $color-yellow !important;
                        }
    
                        svg{
                            width: 2rem;
                        }
                    }
                }
            }
        }

        &__social{
            margin: auto;
            display: flex;
            flex-direction: column;
            position: fixed;
            right: 1.5rem;
            bottom: 1.5rem;
            align-items: center;
            z-index: 2;
            
            &__icon{
                background: linear-gradient(to right, $color-black, $color-gray-light);
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                display: inline-flex;
                justify-content: space-around;
                align-items: center;
                color: $color-white !important;
                transition: .5s;
                margin: .3rem 0;
                border: .2rem solid white;

                svg{
                    width: 1.4rem;
                    height: 1.4rem;
                }

                &:hover{
                    background-color: $color-yellow !important;
                }
            }

            .whatsapp{
                display: inline-flex;
                width: 5rem;
                height: 5rem;
                background-color: $color-whatsapp;
                border-radius: 50%;
                padding: 1rem;
                margin: .3rem 0;
                border: .3rem solid white;

                svg{
                    width: 100%;
                    height: auto;
                }
            }
        }

        .cta{
            margin: auto 0 auto 2rem;
            display: inline-flex;
        }
    }

    &--hide-cta{

        .navigation
        {    
            &__content{
                
                &__menu{
                    margin-right: 0 !important;
                }
            }

        }
    }

    &--fix-top{

        padding: 1.5rem 0;
        max-height: 11rem;

        .navigation
        {
            &__logo{
                img{
                    height: 6rem;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) { 
       &__content{
            
            .cta{
                margin: auto 0 auto 1.5rem;
            }

            &__menu{
                text-align: center;

                a{
                    margin-right: auto;
                    margin-left: 1.5rem;
                    padding: .5rem;
                }
            }
        }

        &__logo{
            img{
                &:first-child{
                    margin-right:  2rem;
                }
            }
        }
    }

    @include media-breakpoint-down(md) { 
        padding: 2rem 0;
        &__bars{
            display: block;
            margin-right: 2rem;
        }

        &__content{
            display: block;
            position: fixed;
            background-color: $color-black;
            background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/tile.jpg);
            top: 11rem;
            left: 0;
            right: 0;            
            max-height: 0;
            padding: 0;
            overflow: hidden;

            .cta{
                border-radius: 0;
                width: 100%;
                margin: 0;
                p{
                    text-align: center;
                }
            }

            &__menu{
                flex-direction: column;
                max-width: 100%;
                padding-top: 1rem;

                a{
                    display: block;
                    width: 100%;
                    padding: 1rem 0;
                    margin-left: 0;
                    
                    
                    p{
                        color: $color-white;
                        text-align: center;
                    }
                }
            }
        }

        &--open{
            
            .navigation{
                
                &__bars{
                    color: $color-yellow !important;
                }

                &__content{
                    max-height: 50rem;
                }
            }
        }

        &--fix-top{
            .navigation
            {
                &__content{   
                    top: 9rem;

                     &__menu{
                        a{
                            p{
                                color: $color-green;
                            }
                        }
                    }
                }
            }
        }
        &--open{
            background-color: $color-header-bg;
        }
    }

    @include media-breakpoint-down(sm) {
        
        &__content{
            &__social{
                &__icon{
                    display: none;
                }
            }

            &__menu--tel{
                a{
                    margin: auto 2rem auto auto;
                    p{
                        font-size: 1.5rem;
                        svg{
                            width: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}
    

