.banner{

    &__home{
        background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/08/galo-banner-bg.jpg);
        background-size: cover;
        background-position: top center;
        padding: 15rem 0 5rem;
        position: relative;

        // &:before{
        //     content: "";
        //     height: 23rem;
        //     width: 100vw;
        //     position: absolute;
        //     top: 4rem;
        //     display: block;
        //     transition: .5s;
        //     background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/base_topo.svg);
        //     background-repeat: repeat-x;
        //     background-size: 1600px 333px;
        //     background-position: bottom center;
    
        // }

        &__solidario{
            padding: 0;

            img{
                max-height: 30rem;
                height: auto;
                width: auto;
                display: inline-block;

                @include media-breakpoint-down(sm) {
                    margin-top: 3rem;
                    display: block;
                    max-height: 30000rem;
                    width: 100%;
                }
            }

            &__conteudo{
                display: flex;
                width: 100%;
                justify-content: space-around;

                @include media-breakpoint-down(sm) {
                    display: block;
                }
            }
            &__texto{
                display: inline-block;
                width: auto;
                padding: 2rem 0 2rem 4rem;

                @include media-breakpoint-down(sm) {
                    padding: 3rem 0;
                }
            }

            h3{
                text-align: left;
                color: $color-black;
            }

            p{

                display: inline-block;
            }

            a{
                background-color: $color-black;
                display: inline-block;

                @include media-breakpoint-down(sm) {
                    display: block;

                    p{
                        text-align: center;
                    }
                }
                
                p{
                    color: $color-yellow !important;
                    margin: 1rem 2rem;
                }
            }
        }

        &--alt-w{
            max-width: 25%;
            @include media-breakpoint-down(sm) {
                max-width: 100000px;
            }
        }
        
        &--alt-w2{
            min-width: 50%;
            @include media-breakpoint-down(sm) {
                min-width: 0;
            }
        }


        @include media-breakpoint-down(sm) {
            padding: 16rem 0 5rem;
        }

        &__conteudo{
            display: inline-flex;
            align-items: center;

            &__imagem{
                max-width: 100%;
                margin: auto;
                max-height: 50rem;

                @include media-breakpoint-down(sm) {
                    max-width: 300px;
                    width: 80%;
                    max-height: 70rem;
                }
                @include media-breakpoint-down(xs) {
                    max-width: 200px;
                }
            }
            
            &__texto{
                h1{
                    display: block;
                    color: $color-black;
                    text-align: left;

                    span{
                        word-spacing: .5rem;
                        span{
                            color: $color-yellow;
                            font-size: 8.5rem;
                        }
                    }

                    @include media-breakpoint-down(sm) { 
                        
                        font-size: 4rem;
                    }
                }
                p{
                    max-width: 26rem !important;
                    color: $color-black;
                    font-size: 1.5rem;
                    text-align: left;
                    line-height: 1.4;
                    margin-bottom: 0 !important;

                    span{
                        font-size: 1rem;
                    }
                }

                &__cta{
                    margin-top: 3rem;
                    background: $color-yellow;
                    border-radius: 15px;
                    display: inline-block;
                    position: relative;
                    
                    p{
                        position: relative;
                        margin: 5px 2rem 5px 0 !important;
                        z-index: 2;
                    }

                    &:before{
                        content: "";
                        height: 100%;
                        position: absolute;
                        left: -100vw;
                        right: 2rem;
                        width: auto;
                        background: $color-yellow;
                        z-index: 0;
                    }
                }
            }
        }

        &__formulario{
            background-color: $color-gray;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            
            &__titulo{
                border-radius: 5px;
                background-color: $color-black;
                padding: 2.5rem 3rem;
    
                p{
                    color: $color-white;
                    text-align: center;
                    font-size: 1.8rem;
                    margin-bottom: 0;
                }
            }
            
            .form{
                padding: 2.5rem 3rem;
            }

            @include media-breakpoint-down(sm) { 
                margin-top: 2rem;
            }
        }
    }

    &__home-2{
        background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/IMAGEM-GALO.jpg);
        background-size: cover;
        background-position: bottom right;
        padding: 6rem 0;

        p{
            margin: 0;
            text-align: justify;
            padding-right: 7.9rem;
            line-height: 1.7;

            span{
                color: $color-gold;
                word-spacing: .2rem;
            }
        }

        @include media-breakpoint-down(xs) {
            p{
                padding-right: 0;
            }
        }    
    }

    &__home-3{
        background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/massa-amarela.jpg);
        background-size: cover;
        background-position: center;
        padding: 6rem 0 3rem;

        img{
            max-width: 100%;

            @include media-breakpoint-down(sm) {
                max-width: 80%;
            } 
        }
    }

    &__home-cta{
        background: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/massa-listrada.jpg);
        background-size: cover;
        background-position: center;
        padding: 3rem 0;

        &__conteudo{
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}