.galeria{

    &__vantagens{
        padding: 2px;
        &__item{
            padding: 2px;
        }

        @include media-breakpoint-down(sm) { 
            .owl-prev, .owl-next{
                top: -20rem;
            }
    
            .owl-prev{
                left: 0rem !important;
            }
        
            .owl-next{
                right: 0rem !important;
            }
        }
    }

    &__economia{
        background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/massa.jpg);
        background-size: cover;
        background-position: center;
        padding: 2rem 0;
        
        &__conteudo{
            padding-top: 1rem !important;
            background-color: $color-white;
            margin: 0 2px;
            position: relative;
            margin: 2rem 0;

            h3{
                display: block;
                width: 100%;
            }

            &__itens{
                display: flex;
                width: 100%;

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                }

                &__item{
                    padding: 1rem 4rem;
                    display: inline-flex;
                    justify-content: space-between;
                    height: 100%;
                    min-height: 15rem;
                    width: 50%;
                    position: relative;
                    

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                        width: 100%;

                        &__texto{
                            p{
                                text-align: center;
                                
                            }
                            margin-bottom: 2rem;
                        }
                    }


                    &:after{
                        content: "";
                        position: absolute;
                        right: -1px;
                        top: 0;
                        bottom: 0;
                        width: 2px;
                        height: 40%;
                        background-color: black;
                        margin: auto;

                        @include media-breakpoint-down(xs) {
                            display: none;
                        }
                    }

                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }

                    &__texto, &__imagem{
                        display: inline-flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;

                        p{
                            margin: 0;
                            white-space: pre-line;

                            &:nth-child(2){
                                color: $color-gold;
                                font-size: 1.7rem;
                            }
                        }
                    }

                    &__texto{
                        flex-grow: 1;
                        max-width: 300px;
                    }
                    
                    &__imagem{
                        padding: 0 2rem;
                        width: auto;

                        img{
                            margin: 0;
                            max-height: 17rem;
                            max-width: 18rem;
                            display: inline-block;
                            width: auto !important;
                        }

                        &--alt{
                            img{
                                margin-bottom: -6rem;

                            }
                        }   
                    }
                }
            }
        }

        h4{
            span{
                color: $color-white;
            }
        }

        .owl-item{
            &:nth-child(even){
                .galeria__economia__conteudo__item{
                    &:after{
                        display: none;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) { 
            .owl-prev, .owl-next{
                top: -30rem !important;
                filter: invert(100%);
            }
    
            .owl-prev{
                left: -1rem !important;
            }
        
            .owl-next{
                right: -1rem !important;
            }
        }
    }

}

.carrossel-faq{

    background-image: url(https://solatioenergialivre.com.br/wp-content/uploads/2021/06/base_rodape.svg);
    background-repeat: repeat-x;
    background-size: 1600px 174px;
    background-position: bottom center;    
    background-color: $color-yellow;
    padding: 3em 0 6rem;

    &__menu{
        margin-top: 4em;
        display: flex;
        justify-content: space-between;
        position: relative;

        &:before{
            content: "";
            position: absolute;
            height: 10px;
            left: 2.5rem;
            right: 2.5rem;
            background-color: $color-white;
            pointer-events: none;
            top:2rem;
            margin: auto;
            z-index: 0;
        }

        &__item{
            width: 4.5rem;
            height:4.5rem;
            background-color: $color-white;
            border-radius: 50%;
            font-size: 2rem;
            text-align: center !important;
            padding-top: .8rem;
            margin-bottom: 1rem;
            cursor: pointer;
            font-weight: 900;
            position: relative;
            z-index: 1;           
        }
    }

    &__content{
        margin: 4em 1.5rem;
        background-color: $color-white;
        position: relative;

        &__pointer{
            position: absolute;
            border: 2rem solid $color-white;
            border-right-color: transparent;
            border-top-color: transparent;
            top: -4rem;
            left: 0;
        }

        &__item{
            padding: 4rem 3rem;
            display: flex;
            align-items: center;
            flex-direction: column;
                        
            h3{
                text-align: left;
                color: black;
                margin-bottom: 1rem;
            }

            p{
                margin-bottom: 0;
            }
        }
    }

    &__sol{
        text-align: right;
        img{
            max-width: 110%;
            margin-left: -2%;
        }
    }

    @include media-breakpoint-down(md) { 
        &__menu{
            &__item{
                padding-top: .8rem;
            }
        }
    }

    @include media-breakpoint-down(sm) { 

        &__menu{
            &:before{
                height: 5px;
                top: 1.5rem;
            }

            &__item{
                width: 3.5rem;
                height: 3.5rem;
                padding-top: .6rem;
            }
        }

        &__content{
            &__item{
                padding: 2rem 1rem;
                h3{
                    font-size: 3rem;
                }
            }
        }
    }
}

.owl-nav{
    position: relative;

    .disabled{
        opacity: .5;
    }

    .owl-prev, .owl-next{
        position: absolute;
        top: -13rem;

        img{
            width: 3rem;
            height: auto;
        }
    }

    .owl-prev{
        left: -4rem;
    }

    .owl-next{
        right: -4rem;
        img{
            transform: rotate(180deg);
        }
    }
}