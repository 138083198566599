﻿@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
    font-family: 'Arcon-Regular';
    src: url('https://solatioenergialivre.com.br/wp-content/themes/solatio-site/build/fonts/Arcon-Regular.otf') format('opentype'), 
    url('https://www.homolog.solatioenergialivre.com.br/wp-content/themes/solatio-site/build/fonts/Arcon-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'Arcon-Rounded-Regular';
    src: url('https://solatioenergialivre.com.br/wp-content/themes/solatio-site/build/fonts/Arcon-Rounded-Regular.otf') format('opentype'),
    url('https://www.homolog.solatioenergialivre.com.br/wp-content/themes/solatio-site/build/fonts/Arcon-Rounded-Regular.otf') format('opentype');
} 