.form{

    .item-consumo{
        display: inline-block;
        text-align: center;
        
        p{
            text-align: center;
            padding: 1rem 0;
            color: $color-white;
        }

        img{
            margin: auto;
            width: 7.5rem !important;
            height: 7.5rem  !important;
            display: block;
            border: solid .4rem $color-white;
            border-radius: 50%;
        }
    }

    .modal{

        &-open{
            padding-right: 0 !important;
        }

        &-content{
            border-radius: 1.5rem;
        }

        &-dialog{
            max-width: 60rem !important;
        }

        &-body{
            padding: 0 7.5rem;
        }
        
        &-header{
            border: none;

            .close{
                margin-left: -1.4rem;
            }
        }
        
        &-title{
            text-align: center;
            width: 100%;
        }

        &__img{
            margin: auto;
            max-width: 18rem;
            max-height: 18rem;
            margin-top: -7.5rem;
        }

        &__text{
            text-align: center;

            span{
                text-decoration: underline;
                color: $color-green;
            }
        }

        &__buttons{
            padding-top: 3rem;
            margin: 0 -13rem -3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            

            .input{
                height: auto;
                line-height: 1.2;
                padding: 1.5rem 3rem;
                margin: 0 2rem;
                white-space: nowrap;
            }

        }
        @include media-breakpoint-down(sm) {
            padding-top: 7rem;

            &__buttons{
                padding-top: 0;
                padding-bottom: 1.5rem;
                margin: 0;
                display: block;
                text-align: center;

                .input{
                    display: inline-block;
                    padding: 1.5rem;
                    margin: 1rem 1.5rem;
                    width: auto;
                }
            }
        }
    }

    .tooltip{

        &-icon{
            border-radius: 50%;
            padding: 1rem;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            color: $color-gray !important;
            transition: .5s;
            margin: 0 1rem;
            position: absolute;
            right: 1.2rem;
            top: -.1rem;
            svg{
                width: 2rem;
                height: 2rem;
            }
        }

        &-inner{
            max-width: 25rem;
        }

        font-size: 1.4rem;

    }

    .files-container{
        border: dashed white 3px;
        height: 100%;
        text-align: center;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    
        p{
            text-align: center;
            margin: auto;
            text-transform: uppercase;
        }

        .react-html5-camera-photo {
            padding-bottom: 100px;
    
            img, video{
                max-width: 100%;   
                max-height: 80%;
            }
        }
    }

    .input-label{
        display: inline-block;
        color: $color-white;
        font-size: 1.6rem;
        width: auto;

        input[type=checkbox], input[type=radio]
        {
            width: 1.8rem;
            height: 1.8rem;
            margin: 0;
            display: inline;
            position: relative;
            vertical-align: middle;
        }

        p{
            display: inline;
            margin: 0;
            vertical-align: middle;

            small{
                font-size: 1.2rem;
            }
        }
    }

    .inputfile
    {
        position: relative;
        margin: 1rem auto 1.5rem;
        color: $color-gray;
        background-color: $color-yellow;
        padding: .5rem 3rem;
        border: none !important;
        transition: .5s;
        border-radius: 3px;
        cursor: pointer;
        width: auto;
        font-size: 1.6rem;
        text-transform: uppercase;
        overflow: hidden;
        
        &:hover, &:focus{
            background-color: $color-white;
        }
        
        input[type=file]
        {
            cursor: pointer;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            overflow: hidden;
            position: absolute;
        }    
    }
    

    .input{

        display: block;
        height: 4rem;
        width: 100%;
        border-radius: 3px;
        border: solid 1px $color-black;
        padding: .5rem 1rem;
        color: $color-gray;
        font-family: 'Poppins';
        font-size: 1.6rem;
        outline: none !important;
        
        & + p, .msg-erro{
            padding-bottom: 1rem;
            margin: 0;
            font-size: 1.4rem;
            text-align: center;
            height: 1.8rem;
            margin-bottom: 1rem;

            span{
                display: none;
            }
        }

        &:focus{
            box-shadow: none !important;
            outline: none !important;
        }

        &--submit{

            color: $color-gray;
            background-color: $color-yellow;
            padding: .5rem 3rem;
            border: none !important;
            transition: .5s;

            &:hover{
                background-color: $color-white;
            }

            &--big{
                font-size: 2.5rem;
                height: 5.5rem;
                &:hover{
                    background-color: $color-white;
                }
            }
        }

        &.is-valid{
            padding-right: 5.5rem;     
            & + p, .msg-erro{
                position: relative;
            }
        }

        &.is-invalid{
            border: solid 1px $color-yellow; 
            padding-right: 5.5rem;       
            & + p, .msg-erro{
                span{
                    display: block;
                }
            }
        }

        &__error{
            color: $color-yellow;
        }
    }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}