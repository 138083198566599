.footer{
    width: 100%;
    padding: 1rem 0 2.5rem;
    position: relative;
    background: $color-white;
    height: auto;
    
    
    &:after{
        content: "";
        height: .9rem;
        width: 100vw;
        position: absolute;
        bottom: 0;
        background: $gradient-gold;
        display: block;
        transition: .5s;
    }

    &__logo{
        cursor: pointer;
        display: inline-block;
        img{
            &:first-child{
                height: 5rem;
                margin-right: 3rem;
            }
            &:last-child{
                height: 3rem;
            }
            width: auto;
        }
    }

    &__links{
        font-size: 1.4rem;

        a:hover{
            color: $color-yellow !important;
        }
    }

    &__social{
        margin: auto;
        display: inline-block;
        margin-left: 0;
        
        &__icon{
            background: linear-gradient(to right, $color-black, $color-gray-light);
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            color: $color-white !important;
            transition: .5s;
            margin: 0 .2rem;

            svg{
                width: 1rem;
                height: 1rem;
            }

            &:hover{
                background-color: $color-yellow;
            }
        }
    }

   &__mail{
       color: $color-black !important;
       float: right;
       display: flex;
       align-items: center;
       transition: .5s;

       @include media-breakpoint-down(sm) {
        justify-content: center;
    }

       &:hover{
           color: $color-yellow !important;

           p{
               color: $color-gray;
           }
       }

       p{
           display: inline-block;
           width: auto;
           color: $color-black;
           margin: 0;
           font-size: 1.2rem;
           transition: .5s;
       }

       svg{
           margin-top: .2rem;
           margin-right: .5rem;;
           height: 1.5rem;
           width: 1.5rem;
           display: inline-block;
       }
   }

   @include media-breakpoint-down(sm) {
    text-align: center;
      &__mail{
          padding-top: 1rem;
          float: none;
          margin: auto;
      }
    }
}